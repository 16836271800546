<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <v-card :loading="loading">
      <v-toolbar elevation="0">
        <v-toolbar-title>{{ rmpData.header.no_ }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn v-if="rmpData.header.status == 'open'" v-show="modulePermission('rmp', 'modify')" icon @click="editData()">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="getDetail()">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn icon @click.stop="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mb-2"></v-divider>
      <v-card-text style="overflow-y:scroll">
        <v-row>
          <v-col cols="12" md="6" class="my-0">
            <v-row class="my-0">
              <v-col cols="12" md="4" class="py-0">
                <b>No.:</b>
              </v-col>
              <v-col cols="12" md="8" class="py-0">{{ rmpData.header.no_ }}</v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="4" class="py-0">
                <b>Doc. Date:</b>
              </v-col>
              <v-col cols="12" md="8" class="py-0">{{ rmpData.header.document_date }}</v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="4" class="py-0">
                <b>Status:</b>
              </v-col>
              <v-col cols="12" md="8" class="py-0">{{ rmpData.header.status }}</v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="4" class="py-0">
                <b>Area:</b>
              </v-col>
              <v-col cols="12" md="8" class="py-0">{{ rmpData.header.area }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="my-0">
            <v-row class="my-0">
              <v-col cols="12" md="4" class="py-0">
                <b>Campaign No.:</b>
              </v-col>
              <v-col cols="12" md="8" class="py-0">{{ rmpData.header.campaign_no_ }}</v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="4" class="py-0">
                <b>Description:</b>
              </v-col>
              <v-col cols="12" md="8" class="py-0">{{ rmpData.header.campaign_description }}</v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider class="mt-2"></v-divider>
        <v-card elevation="0" class="mx-0 my-0">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showDetail = !showDetail" small>
              <v-icon>{{ showDetail ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
              <span>
                <v-badge color="green" :content="rmpData.detail.length">
                  View Detail
                </v-badge>
              </span>
            </v-btn>
            <!-- <v-btn text @click="showDetail = !showDetail">View Detail</v-btn> -->
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showDetail" v-if="isMobile == false">
              <v-divider></v-divider>

              <v-data-table :headers="rmpDataColumns" :items="rmpData.detail" dense>
                <template v-slot:[`item.item_image`]="{ item }">
                  <ImageItem :src="resourceUrl(item.item_image)">
                    <v-img :src="resourceUrl(item.item_image)" width="60"></v-img>
                  </ImageItem>
                </template>
              </v-data-table>
            </div>
            <div v-show="showDetail" v-if="isMobile == true">
              <v-sheet v-for="(item, i) in rmpData.detail" :key="i" outlined class="pa-1" rounded elevation="4">
                <v-row>
                  <v-col cols="4" class="d-flex flex-column justify-center">
                    <ImageItem :src="resourceUrl(item.item_image)" class="mx-auto">
                      <v-img :src="resourceUrl(item.item_image)" width="60"></v-img>
                    </ImageItem>
                  </v-col>
                  <v-col cols="8">
                    <h3>{{ item.item_description }}</h3>
                    <p>Qty: {{ numberFormat(item.quantity, 2) }} | Weight: {{ numberFormat(item.weight, 2) }}</p>
                    <v-chip color="yellow darken-4" dark>{{ item.rm_criteria }}</v-chip>
                  </v-col>
                </v-row>
              </v-sheet>
            </div>
          </v-expand-transition>
        </v-card>
        <v-divider class="mb-2"></v-divider>

        <v-row class="mb-0 pb-0 mt-6">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <b>No. Pol:</b>
              </v-col>
              <v-col cols="12" md="6" class="py-0">{{ rmpData.header.nopol }}</v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-6">
          <v-col cols="12" md="6" class="py-0">
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <b>Loading Date Start:</b>
              </v-col>
              <v-col cols="12" md="6" class="py-0">{{ rmpData.header.loading_date_start }}</v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <b>Loading Date End:</b>
              </v-col>
              <v-col cols="12" md="6" class="py-0">{{ rmpData.header.loading_date_end }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <b>Est Shpt Date Start:</b>
              </v-col>
              <v-col cols="12" md="6" class="py-0">{{ rmpData.header.est_shipment_date_start }}</v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <b>Est Shpt Date End:</b>
              </v-col>
              <v-col cols="12" md="6" class="py-0">{{ rmpData.header.est_shipment_date_end }}</v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-list-item class="px-0" two-line dense v-if="typeof rmpData.header.attachment1 !== 'undefined' && rmpData.header.attachment1 != ''">
          <v-list-item-content>
            <v-list-item-title>Attachment 1</v-list-item-title>
            <v-list-item-subtitle>
              <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment1)">
                <v-icon>mdi-link</v-icon>
                {{ rmpData.header.attachment1 }}
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="px-0" two-line dense v-if="typeof rmpData.header.attachment2 !== 'undefined' && rmpData.header.attachment2 != ''">
          <v-list-item-content>
            <v-list-item-title>Attachment 2</v-list-item-title>
            <v-list-item-subtitle>
              <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment2)">
                <v-icon>mdi-link</v-icon>
                {{ rmpData.header.attachment2 }}
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="px-0" two-line dense v-if="typeof rmpData.header.attachment3 !== 'undefined' && rmpData.header.attachment3 != ''">
          <v-list-item-content>
            <v-list-item-title>Attachment 3</v-list-item-title>
            <v-list-item-subtitle>
              <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment3)">
                <v-icon>mdi-link</v-icon>
                {{ rmpData.header.attachment3 }}
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="px-0" two-line dense v-if="typeof rmpData.header.attachment4 !== 'undefined' && rmpData.header.attachment4 != ''">
          <v-list-item-content>
            <v-list-item-title>Attachment 4</v-list-item-title>
            <v-list-item-subtitle>
              <a href="javascript:;" @click="goToExternalURL(rmpData.header.attachment4)">
                <v-icon>mdi-link</v-icon>
                {{ rmpData.header.attachment4 }}
              </a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-actions class="align-end">
        <v-btn v-show="modulePermission('rmp', 'view')" text @click="redirect('Warehouse.ReturnMaterial.View', { id: rmpData.header.id })" v-if="rmpData.header.status == 'open'">View</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="modulePermission('rmp', 'delete')" color="error" @click="deleteData()" v-if="rmpData.header.status == 'open'">Delete</v-btn>
        <v-btn v-show="modulePermission('rmp', 'release')" color="success" @click="setReleased()" v-if="rmpData.header.status == 'open'">Release</v-btn>
        <v-btn v-show="modulePermission('rmp', 'reopen')" color="warning" @click="setReOpen()" v-if="rmpData.header.status == 'released'">Re-Open</v-btn>
        <v-btn v-show="modulePermission('rmp', 'receive')" color="primary" @click="setReceived()" v-if="rmpData.header.status == 'released'">Receive</v-btn>
        <v-btn v-show="modulePermission('rmp', 'modify')" color="primary" @click="editData()" v-if="rmpData.header.status == 'open'">Edit</v-btn>
        <v-btn @click.stop="dialog = !dialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageItem from './ImageItem.vue'
export default {
  components: {
    ImageItem,
  },
  data() {
    return {
      id: null,
      dialog: null,
      loading: null,
      rmpData: {
        header: {},
        campaign: {},
        detail: [],
      },
      rmpDataColumns: [
        { text: "Image", value: "item_image" },
        { text: "No.", value: "item_no_" },
        { text: "Description", value: "item_description" },
        { text: "UoM", value: "uom" },
        { text: "Weight", value: "weight" },
        { text: "Quantity", value: "quantity" },
      ],
      showDetail: false,
      callback: null,
    }
  },

  methods: {
    getDetail(refresh = false) {
      if (refresh) this.AxiosStorageRemove("GET", "rmp/data-detail");
      this.loading = true
      this.dialog = false;
      this.showLoadingOverlay(true);
      var params = {
        id: this.id,
      };
      this.$axios
        .get("rmp/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.rmpData = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.dialog = true;
          this.loading = false
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.loading = false
          this.axiosErrorHandler(error);
        });
    },
    editData() {
      this.redirect('Warehouse.ReturnMaterial.Edit', { id: this.rmpData.header.id });
    },

    setReleased() {
      if (!confirm("Release?")) return false;
      this.AxiosStorageRemove("GET", "rmp/data-detail");

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.rmpData.header.id);
      this.$axios
        .post("rmp/set-released", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.runCallback();
            this.dialog = false;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    deleteData() {
      if (!confirm("Confirm Delete?")) return false;

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.rmpData.header.id);
      this.$axios
        .post("rmp/set-delete", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.runCallback();
            this.dialog = false;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    setReOpen() {
      if (!confirm("Re-Open data?")) return false;
      this.AxiosStorageRemove("GET", "rmp/data-detail");

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.rmpData.header.id);
      this.$axios
        .post("rmp/set-open", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.runCallback();
            this.dialog = false;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    setReceived() {
      if (!confirm("Receive data?")) return false;
      this.AxiosStorageRemove("GET", "rmp/data-detail");

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.rmpData.header.id);
      this.$axios
        .post("rmp/set-received", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.runCallback();
            this.dialog = false;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    show(id) {
      this.id = id;
      this.getDetail()
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },
    runCallback() {
      this.$emit('callback', true);
    }
  },
}
</script>