<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>New Documents</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" dense class="mb-3 my-0">
        <template v-slot:[`item.no_`]="{ item }" v-if="!isMobile">
          <a href="javascript:;" @click="$refs.refRmpDetail.show(item.id)">{{ item.no_ }}</a>
        </template>
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1" v-text="item.no_"></v-card-title>
            <v-card-subtitle class="my-0 py-0">
              {{ item.document_date }}<br>
              {{ item.campaign_description }}
            </v-card-subtitle>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <span class="text--disabled" style="font-size:12px">{{ item.document_date }}</span>
              <v-spacer></v-spacer>
              <v-btn outlined rounded color="primary" @click="$refs.refRmpDetail.show(item.id)">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>s
      </v-data-table>
    </v-card>
    <RmpDetail ref="refRmpDetail" @callback="getData(true)"></RmpDetail>
  </div>
</template>

<script>
import RmpDetail from './RmpDetail.vue'
export default {
  components: {
    RmpDetail,
  },
  data() {
    return {
      loading: null,
      search: "",
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "No.", value: "no_" },
          { text: "Doc Date", value: "document_date" },
          { text: "Campaign No.", value: "campaign_no_" },
          { text: "Campaign Desc.", value: "campaign_description" },
          { text: "Area", value: "area" },
          { text: "No. Pol", value: "nopol" },
        ],
        data: [],
      },
      dialog: null,
      rmpData: {
        header: {},
        campaign: {},
        detail: [],
      },
      rmpDataColumns: [
        { text: "Image", value: "item_image" },
        { text: "No.", value: "item_no_" },
        { text: "Description", value: "item_description" },
        { text: "UoM", value: "uom" },
        { text: "Weight", value: "weight" },
        { text: "Quantity", value: "quantity" },
      ],
      showDetail: false,
    };
  },

  methods: {
    getData(refresh) {

      if (refresh) this.AxiosStorageRemove("GET", "rmp/data");

      this.datatable.loading = true;
      var params = {
        status: 'open'
      }
      this.$axios
        .get("rmp/data", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  },

  mounted() {
    this.getData()
  }
};
</script>