<template>
  <div>
    <v-container>
      <page-title title="Return Material Project">
        <template slot="action">
          <v-btn color="primary" @click.stop="add()" v-show="modulePermission('rmp', 'create')">Add</v-btn>
        </template>
      </page-title>

      <v-card class="mb-2">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab v-for="item in items" :key="item.tab">{{ item.tab }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <component :is="item.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <v-dialog v-model="dialog" max-width="360px" v-if="modulePermission('rmp', 'create')">
        <v-card>
          <v-card-text class="pb-0">
            <p v-if="loading2 == false && campaignList.length == 0">Campaign Not Found!</p>
            <v-text-field v-model="search2" placeholder="Search Campaign" clearable hide-details></v-text-field>
          </v-card-text>

          <v-skeleton-loader class="pa-0" v-if="loading2" v-bind="attrs" type="list-item-two-line,list-item-two-line,list-item-two-line"></v-skeleton-loader>

          <v-list v-if="loading2 == false" style="height: 400px; overflow-y: auto">
            <v-list-item-group v-model="selectedCampaign" active-class="blue--text">
              <template v-for="(item, i) in searchResults">
                <v-list-item :key="item.no_">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.no_ }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i < campaignList.length - 1" :key="i"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>

          <v-card-actions class="justify-end">
            <v-btn @click.stop="dialog = !dialog">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createDocument()" :disabled="(selectedCampaign == null)">Create Document</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ListDataNew from '../rmp/ListDataNew.vue'
import ListDataReleased from '../rmp/ListDataReleased.vue'
import ListDataReceived from '../rmp/ListDataReceived.vue'
export default {
  components: {
    ListDataNew,
    ListDataReleased,
    ListDataReceived,
  },
  data() {
    return {
      dialog: null,
      search: "",

      loading2: false,
      search2: "",
      campaignList: [],
      attrs: {
        boilerplate: true,
        elevation: 0,
      },
      selectedCampaign: null,
      benched: 0,
      
      tab: null,
      items: [
        { tab: "New", component: 'ListDataNew' },
        { tab: "Released", component: 'ListDataReleased' },
        { tab: "Received", component: 'ListDataReceived' },
      ],
    };
  },

  computed: {
    searchResults() {
      if (this.search2 === "") return this.campaignList;
      var length = this.campaignList.length;
      var items = [];
      for (var i = 0; i < length; i++) {
        if (
          this.campaignList[i].no_.toLowerCase().includes(this.search2) ||
          this.campaignList[i].description.toLowerCase().includes(this.search2)
        ) {
          items.push(this.campaignList[i]);
        }
      }
      return items;
    },
  },

  methods: {
    add() {
      this.dialog = true;

      this.getCampaign();
    },

    getCampaign() {
      this.loading2 = true;
      this.selectedCampaign = null;
      var params = [];
      this.$axios
        .get("rmp/campaign-list", {
          params: params,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.campaignList = resData.data.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading2 = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading2 = false;
        });
    },

    createDocument() {
      this.showLoadingOverlay(true);

      if (typeof this.campaignList[this.selectedCampaign] === "undefined") {
        this.showLoadingOverlay(false);
        this.showAlert("warning", "Select campaign.");

        return null;
      } else {
        var selectedCampaign = this.campaignList[this.selectedCampaign];
        var fm = new FormData();
        fm.append("campaign_no_", selectedCampaign.no_);

        this.$axios
          .post("rmp/create-document", fm)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              this.redirect("Warehouse.ReturnMaterial.Edit", {id: resData.data.id});
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
    },

  },

  mounted() {
    this.requiredLogin();

    this.modulePermission('rmp', 'view', true)
  },
};
</script>